.dd_sel {
  position: absolute;
  padding: 5px;
  min-width: 300px;
  color: #000000;
  background: #fff;
  border-radius: 4px;
  border: 1px #ccc solid;
  box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
  z-index: 19999;
}

.dd_val {
  height: 35px;
  padding: 0px 5px;
  line-height: 35px;
  cursor: hand;
  font-size: 16px;
  text-align: center;
}
.td_val {
  color: #555;
  cursor: hand;
  text-align: center;
}
.dd_btn_val {
  margin: 0px;
  padding: 0px;
  cursor: hand;
}
.dd_top_val {
  height: 50px;
  padding: 0px 5px;
  line-height: 50px;
  cursor: hand;
  font-size: 16px;
  text-align: center;
}
.dd_head {
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 18px;
  border-bottom: 1px #ff9900 solid;
}
.dt_foot {
  width: 100%;
  border-top: 0px #ccc solid;
  font-size: 14px;
}

.dd_content {
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
}
.dd_row {
  margin: 5px 0px;
  padding: 0px 5px;
  height: 40px;
  line-height: 40px;
  color: #222;
  background: #fff;
  border-bottom: 1px #ccc solid;
}
.dd_row:hover {
  color: #fff;
  background: #ff9900;
}

.ddi_content {
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
}
.ddi_row {
  margin: 3px 0px;
  padding: 0px 5px;
  height: 30px;
  line-height: 30px;
  color: #222;
  background: #fff;
  border-bottom: 1px #ccc solid;
}
.ddi_row:hover {
  color: #fff;
  background: #ff9900;
}

.txt_btn {
  width: 125px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  color: #222;
  background: #fff;
}
.txt_btn:hover {
  color: #fff;
  background: #ff9900;
}
.foot_btn {
  width: 125px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  color: #222;
  background: #fff;
}
.foot_btn:hover {
  color: #ff9900;
}
.foot_close {
  width: 100%;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  color: #222;
  background: #fff;
}
.foot_close:hover {
  color: #fff;
  background: #800;
}

.ifoot_close {
  width: 100%;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  color: #222;
  background: #fff;
}
.ifoot_close:hover {
  color: #fff;
  background: #800;
}
