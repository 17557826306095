.hide {
    display: none;
}

.w20, .form-control.w20 {
    width: 20px;
}
.w40, .form-control.w40 {
    width: 40px;
}
.w50, .form-control.w50 {
    width: 50px;
}
.w60, .form-control.w60 {
    width: 60px;
}
.w100, .form-control.w100 {
    width: 100px;
}
.w150, .form-control.w150 {
    width: 150px;
}
.w175, .form-control.w175 {
    width: 175px;
}
.w200, .form-control.w200 {
    width: 200px;
}
.w250, .form-control.w250 {
    width: 250px;
}
.w300, .form-control.w300 {
    width: 300px;
}
.w350, .form-control.w350 {
    width: 350px;
}
.w400, .form-control.w400 {
    width: 400px;
}
.w450, .form-control.w450 {
    width: 450px;
}
.w500, .form-control.w500 {
    width: 500px;
}
.wfull, .form-control.wfull {
    width: 100%;
}

.h35 {
    height: 35px;
}
.h50 {
    height: 50px;
}
.h80 {
    height: 80px;
}
.h100 {
    height: 100px;
}
.h125 {
    height: 125px;
}
.h150 {
    height: 150px;
}
.h175 {
    height: 175px;
}
.h200 {
    height: 200px;
}
.h250 {
    height: 250px;
}

.selected {
    background: #00a900;
    color:#ffffff;
}
.selected tr, .selected td {
    background: #00a900;
    color:#ffffff;
}
.table-hover tbody tr:hover td {
    background: #ff9900;
    color: #ffffff;
}
.table-hover tbody tr.selected:hover td {
    background: #a90000;
    color: #ffffff;
}
