
.mobs-btn {
    padding: 10px 0px;
    border: 0px solid #ccc;
    border-radius: 25px;
    width: 100%;
    min-height: 60px;
    line-height: 60px;
    background: #fee260;
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.txt {
    margin: 0px;
}

.foot-btn {
    margin: 0px;
    padding: 0px;
    border-right: 1px solid #ccc;
    border-radius: 0px;
    height: 55px;
    background: #eee;
    color: #333;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
}
