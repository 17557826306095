.kds-body {
    margin: 0px auto;
    padding: 0px 10px;
    width: 100%;
    font-weight: bold;
}
.kds-grid {
    margin: 0px;
    padding: 0px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
}
.kds-grid .bong-body {
    padding: 0px;
    width: 400px;
    height: 150px;
    line-height: 150px;
    border: 0px solid #ccc;
    border-radius: 5px;
    background: #080;
    color: #fff;
}
