body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.txt_8 {
  font-size: 8px;
}
.txt_10 {
  font-size: 10px;
}
.txt_11 {
  font-size: 11px;
}
.txt_12 {
  font-size: 12px;
}
.txt_14 {
  font-size: 14px;
}
.txt_16 {
  font-size: 16px;
}
.txt_18 {
  font-size: 18px;
}
.txt_20 {
  font-size: 20px;
}
.txt_22 {
  font-size: 22px;
  font-weight: normal;
}
.txt_24 {
  font-size: 22px;
}
.txt_32 {
  font-size: 32px;
}
.txt_42 {
  font-size: 42px;
}
.txt_52 {
  font-size: 52px;
}
.txt_62 {
  font-size: 62px;
}
.txt_72 {
  font-size: 72px;
}
.txt_b16 {
  font-size: 16px;
  font-weight: bold;
}
.txt_b18 {
  font-size: 18px;
  font-weight: bold;
}
.txt_b22 {
  font-size: 22px;
  font-weight: bold;
}
.txt_b32 {
  font-size: 32px;
  font-weight: bold;
}
.font-bold {
  font-weight: bold;
}
.ico-white {
  color: #fff;
}
.ico-gray {
  color: #999;
}
.cursor-pointer {
  cursor: pointer;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
